import queryString from 'query-string';

export function getRedirectUrl(defaultValue) {
  const params = queryString.parse(window.location.search);
  const redirectTo = params.redirectTo || null;
  return redirectTo || defaultValue;
}

export function getUseParse() {
  const params = queryString.parse(window.location.search);
  const useParse = params.useParse || false;
  return useParse;
}

export function getParseStage() {
  const params = queryString.parse(window.location.search);
  const parseStage = params.parseStage || undefined;
  return parseStage;
}
