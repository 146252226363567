import React from 'react';
import { motion } from 'framer-motion';

function Block(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <motion.div whileHover={{ scale: 1.05 }} className="block" {...props} />
  );
}

export default Block;
