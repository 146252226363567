import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.css';
import './global.css';
import App from './App';

// polyfill for window.fetch
import 'whatwg-fetch';

ReactDOM.render(<App />, document.getElementById('root'));
