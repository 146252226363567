import React, { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Logo } from './Logo';
import Grid from './Grid';
import Block from './Block';
import config from '../functions/config';
import { UAParser } from 'ua-parser-js';

export const variants = {
  hidden: { opacity: 0, y: '-100%' },
  visible: { opacity: 1, y: 0 },
};

export function Profile({ user, signOut }) {
  const { t } = useTranslation();
  const [domainsVisible, setDomainsVisible] = useState(false);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    getSessions(user.phoneNumber, setSessions);
  }, [user.phoneNumber, user.domains]);

  const closeSession = useCallback(async (id) => {
    try {
      const res = await fetch(`${config.base_url}/sessions/${id}`, {
        method: 'DELETE',
      });
      const data = await res.text();
      if (res.ok) {
        console.log('data', data);
        getSessions(user.phoneNumber, (fetchedSessions) => {
          setSessions(fetchedSessions);
          if (sessions.length === 0) {
            signOut();
          }
        });
      }
      throw Error(res.statusText);
    } catch (error) {
      console.error('Failed closing session', error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return null;
  }

  return (
    <motion.div
      className="profile"
      initial="hidden"
      animate="visible"
      variants={variants}
    >
      <Logo />
      <h1 className="text-xl font-bold">
        {t('profile-title', { phone: user.phoneNumber })}
      </h1>
      <h2 className="text-lg">{t('services')}</h2>
      <Grid>
        <Block>
          <a href="https://catalog.pinchos.se">
            <h3 className="text-md font-bold">{t('links.catalog')}</h3>
            <p>{t('links.description.catalog')}</p>
          </a>
        </Block>
        <Block>
          <a href="https://restaurant.pinchos.se">
            <h3 className="text-md font-bold">{t('links.restaurant')}</h3>
            <p>{t('links.description.restaurant')}</p>
          </a>
        </Block>
        <Block>
          <a href="https://vip.pinchos.se">
            <h3 className="text-md font-bold">{t('links.vip')}</h3>
            <p>{t('links.description.vip')}</p>
          </a>
        </Block>
      </Grid>
      <div className="flex flex-wrap justify-center items-center mt-8 sign-out">
        <motion.button
          className="bg-neutral-700"
          whileHover={{ scale: 1.1 }}
          type="button"
          onClick={() => signOut()}
        >
          {t('sign-out')}
        </motion.button>
        <>
          <button
            onClick={() => setDomainsVisible(!domainsVisible)}
            type="button"
            className={`ml-4 p-0 m-0 w-auto min-w-0 bg-neutral-700 ${
              domainsVisible ? 'rotate-180' : ''
            }`}
          >
            <svg
              style={{
                width: '24px',
                height: '24px',
              }}
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
              />
            </svg>
          </button>
          {domainsVisible && (
            <div className="w-full mt-2 flex justify-center">
              <ul className="inline-block rounded-md border-2 border-zinc-800">
                {sessions.map((session, i) => {
                  const device = UAParser(session.device);
                  return (
                    <>
                      <li className="p-2 pt-1">
                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-row items-center">
                            <img
                              className="w-6 h-6 mr-3"
                              alt="device type"
                              src={`${device.device.type || 'default'}.png`}
                            />
                            <div className="flex flex-col flex-nowrap">
                              <div className="font-bold">
                                {device.device.model || device.os.name}{' '}
                                {!device.device.model && device.os.version}
                              </div>
                              <div className="text-gray-500 text-xs">
                                {device.browser.name} {device.browser.version}
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => closeSession(session.id)}
                            type="button"
                            className="text-sm ml-4 p-1 px-2 min-w-0 bg-neutral-700"
                          >
                            {t('sign-out')}
                          </button>
                        </div>
                        <ul>
                          {session.domains.map((domain) => (
                            <li className="mt-1 flex justify-between items-center">
                              {domain}{' '}
                              {session.scope !== 'full' && (
                                <button
                                  onClick={() =>
                                    session.domains.length === 1
                                      ? signOut(domain)
                                      : closeSession(session.id)
                                  }
                                  type="button"
                                  className="text-sm ml-2 p-1 min-w-0 bg-red-700"
                                >
                                  <svg
                                    style={{ width: '18px', height: '18px' }}
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                                    />
                                  </svg>
                                </button>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                      {i !== sessions.length - 1 && (
                        <div className="bg-neutral-300 w-full h-px" />
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          )}
        </>
      </div>
    </motion.div>
  );
}
async function getSessions(phoneNumber, cb) {
  const res = await fetch(`${config.base_url}/sessions/${phoneNumber}`);
  const data = await res.json();
  cb(data);
}
