import React from 'react';
import { motion } from 'framer-motion';

export function Logo() {
  return (
    <div className="logo-wrapper">
      <motion.img
        whileHover={{
          rotate: [0, 180, 360],
          scale: [1, 1.1, 1],
          transition: {
            default: {
              ease: 'linear',
              duration: 2,
              loop: Infinity,
            },
            scale: {
              ease: 'easeInOut',
              duration: 3,
              loop: Infinity,
            },
          },
        }}
        className="logo"
        alt="logo"
        src="https://i.pinchos.se/unsafe/100x100/smart/https://static.pinchos.se/icons/pinchos-circle-logo.png"
      />
    </div>
  );
}
