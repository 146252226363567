import React, { useEffect, useState, useCallback } from 'react';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation, Trans } from 'react-i18next';
import { confirmCode, sendCode, bindRecaptcha } from '../utils/firebase-login';
import { signIn } from '../functions/signIn';
import { getRedirectUrl } from '../utils/location';
import { Logo } from './Logo';
import Input from './Input';
import Instruction from './Instruction';
import { LoadingCircle } from './LoadingCircle';
import config from '../functions/config';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { Dialog } from './Dialog';
import Error from './Error';

const variants = {
  hidden: { opacity: 0, y: '-100%' },
  visible: { opacity: 1, y: 0 },
  exit: {
    opacity: 0,
    scale: 0.2,
  },
};

export function Form({
  loading,
  setLoading,
  setError,
  errorComponent,
  onSignIn,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading && !getAuth().currentUser) {
      const recaptcha = bindRecaptcha('recaptcha', (res) => {
        console.log('Recaptcha solved', res);
      });
      recaptcha.render();
      console.log('recaptcha', recaptcha);
      setVerifier(recaptcha);
    }
  }, [loading]);

  const [verifier, setVerifier] = useState(null);
  const [codeResult, setCodeResult] = useState(null);
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [code, setCode] = useState('');
  const [sendingCode, setSendingCode] = useState(false);
  const [resent, setResent] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminDialogIsOpen, setAdminDialogIsOpen] = useState(false);
  const [loginType, setLoginType] = useState('limited');
  const [selectedVenues, setSelectedVenues] = useState([]);
  const phoneNumber = `+${countryCode || ''}${phone}`;

  const onSendCode = useCallback(async () => {
    try {
      setError(null);
      setSendingCode(true);
      const formattedPhoneNumber = parsePhoneNumber(phoneNumber)
        .formatInternational()
        .replace(/ |\+/g, '');
      console.log('verifier', verifier);
      const result = await sendCode(
        formattedPhoneNumber,
        verifier,
        (existsResult) => {
          console.log('existsResult', existsResult);
          return setIsAdmin(existsResult.isAdmin);
        },
      );
      setSendingCode(false);
      return setCodeResult(result);
    } catch (error) {
      setSendingCode(false);
      return setError(error);
    }
  }, [phoneNumber, setError, verifier]);

  const onResendCode = useCallback(async () => {
    try {
      setResent(true);
      setError(null);
      setSendingCode(true);
      const result = await fetch(
        `${config.base_url}/ext/sorestful/request-token`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({ phone: `${countryCode || 46}${phone}` }),
        },
      );
      console.log(result);
      let data = await result.text();
      try {
        data = JSON.parse(data);
      } catch (error) {
        console.log('Keeping text data');
      }
      if (!result.ok) {
        const e = new Error();
        e.message = data;
        throw e;
      }
      setUserId(data);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      setSendingCode(false);
      return setError(error);
    }
  }, [phone, setError, countryCode, setLoading]);

  useEffect(() => {
    if (isAdmin) {
      setAdminDialogIsOpen(true);
    }
  }, [isAdmin]);

  const onPhoneChange = useCallback(({ target: { value } }) => {
    return setPhone(value);
  }, []);
  const onCountryCodeChange = useCallback(({ target: { value } }) => {
    return setCountryCode(value.replace('+', ''));
  }, []);

  const onCodeChange = useCallback(
    ({ target: { value } }) => setCode(value),
    [],
  );

  const onSorestfulLogin = useCallback(async () => {
    try {
      setLoading(true);

      const formattedPhoneNumber = parsePhoneNumber(phoneNumber)
        .formatInternational()
        .replace(/ |\+/g, '');

      const result = await fetch(
        `${config.base_url}/ext/sorestful/validate-token`,
        {
          method: 'POST',
          body: JSON.stringify({
            userId,
            token: code,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        },
      );
      if (!result.ok) {
        const error = new Error();
        const body = await result.json();
        error.message = `${body.message}`;
        setError(error);
        return setLoading(false);
      }
      console.log('result', result);
      const response = await fetch(`${config.base_url}/create-token`, {
        method: 'POST',
        body: JSON.stringify({ phone: formattedPhoneNumber }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const token = await response.text();
      console.log('token', token);

      const userCredentials = await signInWithCustomToken(getAuth(), token);
      const idToken = await userCredentials.user.getIdToken();
      return await signIn(
        `+${formattedPhoneNumber}`,
        idToken,
        loginType,
        selectedVenues,
        async () => {
          console.log('%clogin success', 'color: lightgreen');

          const redirectUrl = getRedirectUrl();
          console.log('redirectUrl', redirectUrl);
          if (redirectUrl) {
            window.location.replace(redirectUrl);
          } else {
            console.log('onSignIn', userCredentials.user);
            onSignIn(userCredentials.user);
            setLoading(false);
          }
        },
      );
    } catch (error) {
      if (error.code) {
        error.message = error.code;
      }
      setError(error);
      console.log('hello from confirm code error', error);
      return setLoading(false);
    }
  }, [
    userId,
    code,
    onSignIn,
    setError,
    setLoading,
    loginType,
    selectedVenues,
    phoneNumber,
  ]);

  const onLogin = useCallback(async () => {
    try {
      setLoading(true);

      const formattedPhoneNumber = parsePhoneNumber(phoneNumber)
        .formatInternational()
        .replace(/ |\+/g, '');

      const { user } = await confirmCode(code, codeResult);
      if (!user) {
        setError({ message: 'invalid-code' });
        return setLoading(false);
      }
      const token = await user.getIdToken();

      return await signIn(
        `+${formattedPhoneNumber}`,
        token,
        loginType,
        selectedVenues,
        () => {
          console.log('%clogin success', 'color: lightgreen');

          const redirectUrl = getRedirectUrl();
          console.log('redirectUrl', redirectUrl);
          if (redirectUrl) {
            window.location.replace(redirectUrl);
          } else {
            console.log('onSignIn', user);
            onSignIn(user);
          }
        },
      );
    } catch (error) {
      if (error.code) {
        error.message = error.code;
      }
      setError(error);
      console.log('hello from confirm code error', error);
      return setLoading(false);
    }
  }, [
    setLoading,
    phoneNumber,
    code,
    codeResult,
    loginType,
    selectedVenues,
    setError,
    onSignIn,
  ]);

  const Resend = () => (
    <button
      className="linky bg-neutral-700"
      type="button"
      onClick={onResendCode}
    >
      {t('here')}
    </button>
  );

  return (
    <>
      <AnimatePresence>
        <motion.form
          key="form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          noValidate
          className="login-form"
          positionTransition
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={variants}
        >
          <Logo />
          <div className="instructions">
            <strong>{t('instruction-header')}</strong>
            {!codeResult && (
              <Instruction>{t('instruction-body-1')}</Instruction>
            )}
            {codeResult && <Instruction>{t('instruction-body-2')}</Instruction>}
          </div>
          {!codeResult && (
            <>
              <Input
                className="country-code"
                type="text"
                value={countryCode ? `+${countryCode}` : ''}
                onChange={onCountryCodeChange}
                placeholder="+"
                inputMode="numeric"
                autoComplete="off"
              />
              <Input
                className="phone-number"
                type="text"
                value={phone}
                onChange={onPhoneChange}
                placeholder="123456789"
                inputMode="numeric"
                autoComplete="off"
              />
              <button
                className="row bg-neutral-700"
                type="submit"
                disabled={!isValidPhoneNumber(phoneNumber)}
                onClick={onSendCode}
              >
                {t('send-code')}
                {sendingCode && <LoadingCircle />}
              </button>
              {phone && phone.length && !isValidPhoneNumber(phoneNumber) && (
                <Error>
                  {phoneNumber} {t('invalid-number-error')}
                </Error>
              )}
              {errorComponent}
            </>
          )}
          {(userId || codeResult) && (
            <>
              <Input
                type="text"
                autoFocus
                value={code}
                onChange={onCodeChange}
                placeholder={t('enter-code')}
                inputMode="numeric"
                autoComplete="one-time-code"
              />
              <button
                className="bg-neutral-700"
                type="submit"
                onClick={
                  resent && code && code.length === 4
                    ? onSorestfulLogin
                    : onLogin
                }
              >
                {t('log-in')}
              </button>
              {errorComponent}
              <div className="instructions">
                <Instruction>
                  <Trans i18nKey="code-hint">
                    Didn&apos;t get an SMS? Click <Resend />
                    to resend. Still no code? Reload and try again.
                  </Trans>
                </Instruction>
              </div>
            </>
          )}
        </motion.form>
      </AnimatePresence>
      <Dialog
        open={adminDialogIsOpen}
        onClose={() => setAdminDialogIsOpen(false)}
        onSubmit={(option, venues) => {
          setAdminDialogIsOpen(false);
          setSelectedVenues(venues);
          setLoginType(option);
        }}
      />
    </>
  );
}
