import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'instruction-header': 'Login',
      'instruction-body-1':
        'Enter your phone number, with country code, below.',
      'instruction-body-2': 'Enter the code you got via SMS below to continue!',
      'code-hint': "Didn't get an SMS? Click <1>here</1> to resend.",
      here: 'here',
      'send-code': 'Send code',
      'invalid-number-error':
        'is not a valid number. Remember to enter the country code in the left field.',
      continue: 'Continue',
      'log-in': 'Log in',
      'sign-out': 'Log out',
      'enter-code': 'Enter SMS-code...',
      'profile-title': "You're logged in with {{phone}}!",
      services: 'Some useful links:',
      links: {
        catalog: 'Catalog',
        host: 'Reservations',
        terminal: 'Terminal',
        vip: 'VIP',
        restaurant: 'Restaurant',
        description: {
          catalog: 'Manage menu etc.',
          host: 'Manage reservations',
          terminal: 'Service tools',
          vip: 'News, quizes etc.',
          restaurant: 'Cashier, reservations, etc.',
        },
      },
      superUserDialog: {
        title: 'Super user login',
        subtitle:
          'Pick the appropriate option below depending on where you are logging in.',
        fullWarning: 'Only pick Full on private devices.',
        types: {
          full: {
            title: 'Full (all Pinchos web applications)',
            tags: {
              phone: 'Private phone',
              pc: 'Private computer',
            },
          },
          nonAdmin: {
            title: 'As non-super user (only {{host}})',
            tags: {
              screens: 'Restaurant screens',
              devices: 'Employee devices',
            },
          },
        },
      },
      errors: {
        'sign-in-failed':
          '<strong>Sign in failed.</strong><br />Try again, if it keeps happening report it to IT.',
        'no-user':
          '<strong>No user found for that phone number.</strong><br />Make sure you entered it correctly! If it seems correct, make sure your restaurang manager has added you to the organisation.',
        'auth/invalid-verification-code':
          "That code doesn't seem to be correct, try again.",
        'auth/network-request-failed':
          'No internet detected, check your connection and try again.',
        'auth/user-disabled': 'Your account has been temporarily disabled.',
        'auth/captcha-check-failed':
          'Anti-abuse check failed, reload and try again.',
        'auth/invalid-phone-number': 'Entered phone number is invalid.',
        'auth/missing-phone-number': 'Missing phone number.',
        'auth/code-expired': 'Code expired, try again',
        'auth/quota-exceeded':
          "Can't log in because of too many attempts recently, wait a while and try again.",
        'auth/missing-verification-code': 'Verification code is required.',
        'auth/too-many-requests':
          'Too many attempts from this device, try again in 10 minutes.',
        'missing-phone': 'Please enter a phone number',
        'firestore-session-expired':
          'Your login session has expired. Log in again.',
        'no-session-found': 'You have been logged out',
        'unknown-error': 'Unknown error: {{error}}',
      },
    },
  },
  sv: {
    translation: {
      'instruction-header': 'Inloggning',
      'instruction-body-1': 'Skriv in ditt telefonummer med landskod nedan.',
      'instruction-body-2':
        'Skriv in koden du fått via SMS nedan för att fortsätta!',
      'code-hint': 'Fick du inget SMS? Klicka <1/> för att skicka igen.',
      here: 'här',
      'send-code': 'Skicka kod',
      'invalid-number-error':
        'är inte ett korrekt telefonnummer. Kom ihåg att skriva in landskod i det vänstra fältet.',
      continue: 'Continue',
      'log-in': 'Logga in',
      'sign-out': 'Logga ut',
      'enter-code': 'Skriv SMS-kod...',
      'profile-title': 'Du är inloggad med {{phone}}!',
      services: 'Några användbara länkar:',
      links: {
        catalog: 'Katalogen',
        host: 'Bokningar',
        terminal: 'Terminalen',
        vip: 'VIP',
        restaurant: 'Restaurant',
        description: {
          catalog: 'Hantera meny m.m.',
          host: 'Hantera bokningar',
          terminal: 'Servicetjänster',
          vip: 'Nyheter, quiz m.m.',
          restaurant: 'Kassa, bokningar, osv.',
        },
      },
      superUserDialog: {
        title: 'Super user-inloggning',
        subtitle: 'Välj alternativ nedan baserat på var du loggar in.',
        fullWarning: 'Välj enbart Fullständig på privata enheter.',
        types: {
          full: {
            title: 'Fullständig (alla Pinchos webbappar)',
            tags: {
              phone: 'Privat telefon',
              pc: 'Privat dator',
            },
          },
          nonAdmin: {
            title: 'Som icke-super user (enbart {{host}})',
            tags: {
              screens: 'Restaurangskärm',
              devices: 'Anställdas enheter',
            },
          },
        },
      },
      errors: {
        'sign-in-failed':
          '<strong>Inloggning misslyckades.</strong><br />Försök igen, om det fortsätter hända rapportera till IT.',
        'no-user':
          '<strong>Hittade ingen användare för det telefonnumret.</strong><br/>Se till att du skrivit rätt. Om det ser korrekt ut, kolla så din restaurangchef har lagt till dig i organisationen.',
        'auth/invalid-verification-code':
          'Den koden verkar inte ha varit korrekt, försök igen!',
        'auth/network-request-failed':
          'Kunde inte logga in pågrund av nätverksfel. Kolla din uppkoppling och försök igen.',
        'auth/user-disabled':
          'Ditt konto har blivit avstängt av administratör.',
        'auth/captcha-check-failed':
          'Verifikation misslyckades, ladda om och försök igen.',
        'auth/invalid-phone-number':
          'Felaktigt telefonnummer inmatat, dubbelkolla att du skrivit rätt!',
        'auth/missing-phone-number': 'Telefonnummer är obligatoriskt.',
        'auth/code-expired': 'Koden har gått ut, försök igen',
        'auth/quota-exceeded':
          'För många inloggningsförsök har gjorts, vänta ett tag och försök igen.',
        'auth/missing-verification-code': 'Verifikationskod är obligatoriskt.',
        'auth/too-many-requests':
          'För många inloggningsförsök, vänta i 10 minuter och försök igen.',
        'missing-phone': 'Skriv in ett telefonnummer',
        'firestore-session-expired':
          'Din inloggning har gått ut, logga in igen',
        'no-session-found': 'Du har blivit utloggad',
        'unknown-error': 'Okänt fel: {{error}}',
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    debug: true,
    fallbackLng: 'en',
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'navigator'],

      // keys or params to lookup language from
      lookupQuerystring: 'lng',
    },

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
