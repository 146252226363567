import Auth from '@pinchonation/auth-utils';
import { getAuth, signOut, signInWithCustomToken } from 'firebase/auth';
import firebase from './firebase-init';
import config from '../functions/config';

export const firebaseHooks = {
  getAuth,
  signOut,
  signInWithCustomToken,
};

export const utils = new Auth(config.base_url, firebase, firebaseHooks);
