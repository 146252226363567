import React from 'react';

import version from '../version';

export function Version() {
  if (!version) {
    return null;
  }
  return (
    <div
      style={{
        color: 'slategray',
        position: 'fixed',
        top: '5px',
        right: '5px',
      }}
    >
      {version}
    </div>
  );
}
