import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const variants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
  },
  exit: {
    scale: 0.4,
  },
};

const Error = (props) => (
  <AnimatePresence>
    <div className="error-wrapper">
      <motion.p
        className="error"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={variants}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </div>
  </AnimatePresence>
);

export default Error;
