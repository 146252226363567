import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const variants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
  },
  exit: {
    scale: 0.4,
  },
};

const Instruction = (props) => (
  <AnimatePresence>
    <motion.p
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={variants}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  </AnimatePresence>
);

export default Instruction;
