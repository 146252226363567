import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getHost } from '../functions/signIn';
import { getVenues } from '../utils/statics';

const WarningIcon = () => (
  <svg
    style={{
      display: 'inline-block',
      width: '24px',
      height: '24px',
    }}
    viewBox="-5 -5 36 36"
  >
    <path
      className="fill-red-600"
      d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z"
    />
  </svg>
);

export const Dialog = ({ open, onClose = () => {}, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const [option, setOption] = useState('full');
  const [venues, setVenues] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState([]);
  useEffect(() => {
    (async function () {
      setVenues(await getVenues());
    })();
  }, []);
  if (!open) {
    return null;
  }
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed w-screen h-screen top-0 left-0"
      >
        <div className="relative w-full h-full flex justify-center items-center p-2">
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="relative max-w-sm bg-white rounded-md z-10 p-4"
          >
            <h1 className="text-lg font-bold mb-2">
              {t('superUserDialog.title')}
            </h1>
            <p className="text-sm">{t('superUserDialog.subtitle')}</p>
            <p className="text-sm leading-[24px]">
              <WarningIcon />
              {t('superUserDialog.fullWarning')}
            </p>
            <ul className="mt-4">
              <li
                onClick={() => setOption('full')}
                className="flex flex-row items-center cursor-pointer"
              >
                <span
                  className={`rounded-full border-2 border-gray-800 w-3 h-3 mr-4 transition-colors ${
                    option === 'full' ? 'bg-blue-400 border-transparent' : ''
                  }`}
                />
                <div className="flex flex-col flex-nowrap">
                  <div>
                    <WarningIcon /> {t('superUserDialog.types.full.title')}
                  </div>
                  <div className="flex">
                    <div className="rounded-xl px-2 text-xs bg-neutral-700 text-white mr-1">
                      {t('superUserDialog.types.full.tags.phone')}
                    </div>
                    <div className="rounded-xl px-2 text-xs bg-neutral-700 text-white mr-1">
                      {t('superUserDialog.types.full.tags.pc')}
                    </div>
                  </div>
                </div>
              </li>
              {/* <li
                onClick={() => setOption('limited')}
                className="flex flex-row items-center cursor-pointer mt-2"
              >
                <span
                  className={`rounded-full border-2 border-gray-800 w-3 h-3 mr-4 transition-colors ${
                    option === 'limited' ? 'bg-blue-400 border-transparent' : ''
                  }`}
                />
                Limited (only {getHost()})
              </li> */}
              <li
                onClick={() => setOption('non-admin')}
                className="flex flex-row items-center cursor-pointer mt-2"
              >
                <span
                  className={`rounded-full border-2 border-gray-800 w-3 h-3 mr-4 transition-colors ${
                    option === 'non-admin'
                      ? 'bg-blue-400 border-transparent'
                      : ''
                  }`}
                />
                <div className="flex flex-col flex-nowrap">
                  <div>
                    {t('superUserDialog.types.nonAdmin.title', {
                      host: getHost(),
                    })}
                  </div>
                  <div className="flex">
                    <div className="rounded-xl px-2 text-xs bg-neutral-700 text-white mr-1">
                      {t('superUserDialog.types.nonAdmin.tags.screens')}
                    </div>
                    <div className="rounded-xl px-2 text-xs bg-neutral-700 text-white mr-1">
                      {t('superUserDialog.types.nonAdmin.tags.devices')}
                    </div>
                  </div>
                </div>
              </li>
              {option === 'non-admin' && (
                <li className="mt-4 mb-4 h-full rounded-md bg-neutral-700 text-white">
                  <select
                    className="w-full h-full bg-transparent p-2"
                    name="venue"
                    id="venue"
                    multiple
                    onChange={(e) =>
                      setSelectedVenues(
                        Array.from(
                          e.target.selectedOptions,
                          (option) => option.value,
                        ),
                      )
                    }
                  >
                    {venues.map((venue) => (
                      <option value={venue.id}>{venue.title}</option>
                    ))}
                  </select>
                </li>
              )}
            </ul>
            <div className="mt-4 flex justify-end">
              <button
                disabled={option === 'non-admin' && !selectedVenues.length}
                onClick={() => onSubmit(option, selectedVenues)}
                type="button"
                className="text-sm bg-neutral-700"
              >
                {t('continue')}
              </button>
            </div>
          </motion.div>
          <div className="absolute top-0 left-0 bg-black bg-opacity-40 w-full h-full" />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
