import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  useDeviceLanguage,
} from 'firebase/auth';
import { utils } from './utils-init';

// Set language to browser default for recaptcha
// eslint-disable-next-line react-hooks/rules-of-hooks
useDeviceLanguage(getAuth());

/**
 * Calls the cloud function for checking whether a user exists or not
 * @param {String} phoneNumber - Phone number for user we want to check
 * @returns {Boolean} whether user exists or not
 */
async function checkIfUserExists(phoneNumber) {
  const result = await utils.exists(phoneNumber);
  return result;
}

/**
 * Binds an invisible recaptcha verifier to a DOM element
 * @param {String} element - the element id
 * @param {Function} callback - function to run on verification success
 * @returns firebase.auth.RecaptchaVerifier
 */
export function bindRecaptcha(element, callback) {
  console.log('RecaptchaVerifier bound to', element);
  return new RecaptchaVerifier(
    element,
    {
      size: 'invisible',
      callback,
    },
    getAuth(),
  );
}

/**
 * Checks if user exists and if it does sends a code via SMS to the provided number
 * @param {*} phoneNumber - Phone number for user we want to send code to
 * @param {firebase.auth.RecaptchaVerifier} [appVerifier=window.recaptchaVerifier] - instance of RecaptchaVerifier
 * @returns firebase.auth.ConfirmationResult
 */
export async function sendCode(
  phoneNumber,
  appVerifier = window.recaptchaVerifier,
  onExists = () => {},
) {
  console.log('appVerifier', appVerifier);
  const result = await checkIfUserExists(phoneNumber);
  if (!result?.exists) {
    throw Error('no-user');
  }
  onExists(result);
  const confirmationResult = await signInWithPhoneNumber(
    getAuth(),
    `+${phoneNumber}`,
    appVerifier,
  );
  return confirmationResult;
}

/**
 * Confirm the SMS code provided by the user
 * @param {String} code - 6 length PIN code sent to the user via SMS via sendCode
 * @param {firebase.auth.ConfirmationResult} confirmationResult
 * @returns firebase.auth.UserCredential
 */
export async function confirmCode(code, confirmationResult) {
  if (!confirmationResult) {
    throw Error('no confirmation result found');
  }
  console.log(confirmationResult);
  const result = await confirmationResult.confirm(code);
  console.log('User confirmed', result);
  return result;
}
