import React from 'react';
import { motion } from 'framer-motion';

const svgVariants = {
  hidden: {
    opacity: 0,
    y: '100%',
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.2,
    },
  },
};

const circleVariants = {
  visible: (delay) => ({
    y: [0, 20],
    transition: {
      delay,
      ease: [0.7, 0, 0.7, 0],
      duration: 0.4,
      yoyo: Infinity,
    },
  }),
};

export function Loading({ label }) {
  return (
    <div className="loader-wrapper">
      {label && <h3>{label}</h3>}
      <motion.svg
        initial="hidden"
        animate="visible"
        variants={svgVariants}
        className="loader"
        viewBox="0 0 200 100"
      >
        <motion.circle
          custom={0}
          variants={circleVariants}
          cx="25"
          cy="50"
          r="25"
        />
        <motion.circle
          custom={0.15}
          variants={circleVariants}
          cx="100"
          cy="50"
          r="25"
        />
        <motion.circle
          custom={0.3}
          variants={circleVariants}
          cx="175"
          cy="50"
          r="25"
        />
      </motion.svg>
    </div>
  );
  // return (
  //   <motion.svg
  //     className="loader"
  //     xmlns="http://www.w3.org/2000/svg"
  //     viewBox="0 0 200 200"
  //   >
  //     <motion.path
  //       d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
  //       animate={{
  //         // opacity: [0, 1],
  //         pathLength: [0.2, 0.8, 0.8, 0.2],
  //         pathOffset: [0.2, 0.4, 0.6, 0.4],
  //         rotate: [0, 120, 240, 360],
  //       }}
  //       transition={{
  //         default: {
  //           duration: 2,
  //           ease: 'linear',
  //           times: [0, 0.33333333, 0.666666666666, 1],
  //           loop: Infinity,
  //           repeatDelay: 0,
  //         },
  //         pathOffset: {
  //           duration: 7,
  //           ease: 'linear',
  //           times: [0, 0.4, 0.6, 1],
  //           yoyo: Infinity,
  //           repeatDelay: 0,
  //         },
  //         pathLength: {
  //           duration: 4,
  //           ease: 'linear',
  //           times: [0, 0.4, 0.6, 1],
  //           yoyo: Infinity,
  //           repeatDelay: 0,
  //         },
  //       }}
  //     />
  //   </motion.svg>
  // );
}
