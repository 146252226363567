import config from './config';
import { getUseParse, getParseStage, getRedirectUrl } from '../utils/location';

export function getHost() {
  try {
    const redirectUrl = getRedirectUrl();
    const { host } = new URL(redirectUrl);
    return host;
  } catch (error) {
    return window.location.host;
  }
}

export async function signIn(
  phoneNumber,
  idToken,
  loginType,
  venues,
  onSuccess,
) {
  const useParse = getUseParse();
  const parseStage = getParseStage();
  const host = getHost();

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  const result = await fetch(`${config.base_url}/sign-in`, {
    method: 'POST',
    mode: 'cors',
    redirect: 'follow',
    credentials: 'include', // Don't forget to specify this if you need cookies
    headers,
    body: JSON.stringify({
      phoneNumber,
      idToken,
      useParse,
      parseStage,
      loginType,
      host,
      venues,
    }),
  });
  if (!result.ok) {
    throw Error('sign-in-failed');
  }
  onSuccess();
  console.log(result);
  return result;
}
