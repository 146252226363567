/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';

import { useTranslation, Trans } from 'react-i18next';
import { getAuth } from 'firebase/auth';
import { Form } from './components/Form';
import { getRedirectUrl } from './utils/location';
import { Loading } from './components/Loading';
import { Profile } from './components/Profile';
import { utils } from './utils/utils-init';
import { Version } from './components/Version';
import Error from './components/Error';
import { getHost } from './functions/signIn';

function App() {
  const { t } = useTranslation();
  const redirectUrl = getRedirectUrl();

  const signOut = useCallback(
    async (host = '*') => {
      setLoading(true);
      setLoadingLabel('Logging you out');
      try {
        await utils.signOut({ host });
        if (host === '*') {
          setUser(null);
        } else {
          checkUser();
        }
      } catch (err) {
        if (err.message === 'no-session-found') {
          console.log('setting user');
          setUser(null);
        }
        setError(err);
      }
      setLoading(false);
      setLoadingLabel(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const checkUser = useCallback(async () => {
    try {
      const user = await utils.check(false, { host: getHost() });
      await utils.me();
      if (redirectUrl) {
        window.location.replace(redirectUrl);
      }
      setUser({ ...user, phoneNumber: user.phone_number });
      setLoading(false);
    } catch (error) {
      console.log('error.message', error.message);
      if (error.message === 'no-session-found') {
        setUser(null);
      }
      if (error.message !== 'unauthorized-for-host') {
        signOut();
        setError(error);
      }
      setLoading(false);
      console.error(error);
    }
  }, [redirectUrl, signOut]);

  useEffect(() => {
    const validCookies = utils.validateCookies();
    if (validCookies) {
      checkUser();
    } else {
      setLoading(false);
    }
  }, [checkUser, redirectUrl, signOut]);

  const [loading, setLoading] = useState(true);
  const [loadingLabel, setLoadingLabel] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(getAuth().currentUser);

  console.log('loading', loading);
  console.log('user', user);

  return (
    <div className="App">
      {loading && (
        <div className="loading-bg">
          <Loading label={loadingLabel} />
        </div>
      )}
      {user ? (
        <Profile user={user} signOut={signOut} />
      ) : (
        <>
          <Form
            loading={loading}
            setLoading={setLoading}
            onSignIn={checkUser}
            setError={setError}
            errorComponent={
              error && (
                <Error>
                  <Trans
                    i18nKey={`errors.${error.message}`}
                    defaults={t('errors.unknown-error', {
                      error: error.message,
                    })}
                  />
                </Error>
              )
            }
          />
        </>
      )}
      {!user && <div id="recaptcha" />}
      <Version />
    </div>
  );
}

export default App;
