import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';

const firebaseConfig = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      // ////////////////
      // Prod
      return {
        apiKey: 'AIzaSyA0A9nzyg37yNOSe2CdAMB32rgm5m7BgNw',
        authDomain: 'blupp-43119.firebaseapp.com',
        databaseURL: 'https://blupp-43119.firebaseio.com',
        projectId: 'blupp-43119',
        storageBucket: 'blupp-43119.appspot.com',
        messagingSenderId: '522680239423',
        appId: '1:522680239423:web:e990b4c766f22df2ca7f8a',
      };
    default:
      return {
        // ///////////////
        // DEV
        apiKey: 'AIzaSyDonjw9AVuSnxAA0kD98PAvzBNoR7NYfqk',
        authDomain: 'blupp-dev.firebaseapp.com',
        databaseURL: 'https://blupp-dev.firebaseio.com',
        projectId: 'blupp-dev',
        storageBucket: 'blupp-dev.appspot.com',
        messagingSenderId: '478303660837',
        appId: '1:478303660837:web:190dead616ee9064d088cc',
      };
  }
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig());
console.log('firebase', firebase);

export default firebase;
